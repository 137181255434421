import { useDispatch, useSelector } from "react-redux";
import styles from "./Pages.module.css";
import { updatePage } from "../../redux/pageSlice";
function Pages({ totalPages }) {
  const pageValue = useSelector((state) => state.page.pageValue);
  const dispatch = useDispatch();
  return (
    <div className={styles.allPages}>
      <div className={styles.navContainer}>
        {Array.from({ length: totalPages - 1 }, (_, index) => (
          <button
            className={
              pageValue === index + 1 ? styles.activeBtn : styles.button
            }
            onClick={() => dispatch(updatePage(index + 1))}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <button
        className={pageValue === totalPages ? styles.activeBtn : styles.button}
        onClick={() => dispatch(updatePage(totalPages))}
      >
        {totalPages}
      </button>
    </div>
  );
}

export default Pages;
