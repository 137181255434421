import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MdArrowBack, MdNotifications, MdOutlineChat } from "react-icons/md";
import { LangContext, LanguageSelector } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import styles from "./navbar.module.css";
import { FaAccessibleIcon, FaArrowLeftLong, FaRegUser } from "react-icons/fa6";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { lang } = useContext(LangContext);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FaArrowLeftLong
          onClick={() => navigate(-1)}
          style={{ color: "#0c356a", cursor: "pointer" }}
        />
      </div>
      <div>
        <Link to="/dashboard/sold" className={styles.logoutBtn}>
          {t("Codes")}
        </Link>{" "}
        {/* Link to the "sold" page */}
      </div>
      <div>
        <Link to="/dashboard" className={styles.logoutBtn}>
          {t("Dashboard")}
        </Link>{" "}
        {/* Link to the "sold" page */}
      </div>

      <div className={styles.menu}>
        <div className={styles.icons}>
          {isAuthenticated && (
            <div className={styles.userInfo}>
              <p className={styles.username}>
                <FaRegUser />
                {user.username}
              </p>
              <button className={styles.logoutBtn} onClick={handleLogout}>
                {t("logOut")}
              </button>
            </div>
          )}
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
