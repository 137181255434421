import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AreaCard from "./AreaCard";
import "./AreaCards.scss";

const AdminCards = () => {
  const [userCount, setUserCount] = useState(0);
  const [codeCount, setCodeCount] = useState(0);
  const [soldCode, setSoldCode] = useState(0);
  const { t } = useTranslation();

  const Token = localStorage.getItem("token");
  const user = useSelector((state) => state.auth.user);
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${base_url}user/getAllUsers`, {
          params: { type: "reseller" },
          headers: { "X-Auth": Token, "Content-Type": "application/json" },
        });
        const numberOfUsers = response.data.totalcount;
        setUserCount(numberOfUsers);
        console.log(response);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchCodeData = async () => {
      try {
        const response = await axios.get(`${base_url}codes/codes`, {
          headers: { "X-Auth": Token },
        });
        const numberOfCodes = response.data.totalcount;
        const numberOfSoldCodes = response.data.data;

        const soldCodeCount = numberOfSoldCodes.reduce(
          (count, item) => (item.soldDate ? count + 1 : count),
          0
        );

        setSoldCode(soldCodeCount);
        setCodeCount(numberOfCodes);
      } catch (error) {
        console.error("Error fetching code data:", error);
      }
    };

    fetchUserData();
    fetchCodeData();
  }, [Token]);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={codeCount}
        cardInfo={{
          title: t("TotalCodes"),
          value: codeCount,
          text: `${t("TotalCodeDec")}: ${soldCode}`,
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={userCount}
        cardInfo={{
          title: t("TotalReseller"),
          value: userCount,
          text: `${t("TotalResellerDec")}: ${userCount}`,
        }}
      />
    </section>
  );
};

export default AdminCards;
