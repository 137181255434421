// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginForm_form__jz3sR {
  background-color: var(--bgSoft);
  padding: 50px;
  border-radius: 10px;
  width: 500px;
  font-family: "Cairo";

  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.loginForm_container__IeHmr {
  display: flex;
  justify-content: center;
  padding: 100px;
}

.loginForm_error__msg__5AT6p {
  color: red;
  text-align: center;
  font-family: "Cairo";

}

.loginForm_form__jz3sR input {
  width: 100%;
  padding: 25px;
  border: 2px solid #2e374a;
  border-radius: 5px;
  background-color: var(--bg);
  font-family: "Cairo";
  color: var(--text);
}

.loginForm_form__jz3sR button {
  width: 100%;
  padding: 20px;
  font-family: "Cairo";

  background-color: #194cbc;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/screen/login/loginForm.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;;EAEpB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,oBAAoB;;AAEtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,2BAA2B;EAC3B,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,oBAAoB;;EAEpB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".form {\n  background-color: var(--bgSoft);\n  padding: 50px;\n  border-radius: 10px;\n  width: 500px;\n  font-family: \"Cairo\";\n\n  height: 500px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 30px;\n}\n.container {\n  display: flex;\n  justify-content: center;\n  padding: 100px;\n}\n\n.error__msg {\n  color: red;\n  text-align: center;\n  font-family: \"Cairo\";\n\n}\n\n.form input {\n  width: 100%;\n  padding: 25px;\n  border: 2px solid #2e374a;\n  border-radius: 5px;\n  background-color: var(--bg);\n  font-family: \"Cairo\";\n  color: var(--text);\n}\n\n.form button {\n  width: 100%;\n  padding: 20px;\n  font-family: \"Cairo\";\n\n  background-color: #194cbc;\n  color: white;\n  border: none;\n  cursor: pointer;\n  border-radius: 30px;\n  -webkit-border-radius: 30px;\n  -moz-border-radius: 30px;\n  -ms-border-radius: 30px;\n  -o-border-radius: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `loginForm_form__jz3sR`,
	"container": `loginForm_container__IeHmr`,
	"error__msg": `loginForm_error__msg__5AT6p`
};
export default ___CSS_LOADER_EXPORT___;
