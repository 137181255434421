import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { FaArrowUpLong } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";

import "./AreaCharts.scss";
import { LangContext } from "../../App";
import { useTranslation } from "react-i18next";

const AdminBarChart = () => {
  const [soldData, setSoldData] = useState([]);
  const [lastMonthSales, setLastMonthSales] = useState(0);
  const [currentMonthSales, setCurrentMonthSales] = useState(0);
  // const { theme } = useContext(ThemeContext);
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();
  const Token = localStorage.getItem("token");
  axios.defaults.headers.common["X-Auth"] = Token;

  const user = useSelector((state) => state.auth.user);
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${base_url}codes/codes`, {
          headers: { "X-Auth": ` ${Token}` },
        });
        setSoldData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (soldData.length > 0) {
      // Extract current month sales
      const currentDate = new Date();
      const currentMonthYear = currentDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const currentMonthSales = soldData.filter((item) => {
        const soldDate = new Date(item.soldDate);
        const monthYear = soldDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        return monthYear === currentMonthYear;
      }).length;

      // Extract last month sales
      const lastDate = new Date();
      lastDate.setMonth(lastDate.getMonth() - 1);
      const lastMonthYear = lastDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      const lastMonthSales = soldData.filter((item) => {
        const soldDate = new Date(item.soldDate);
        const monthYear = soldDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        return monthYear === lastMonthYear;
      }).length;

      setCurrentMonthSales(currentMonthSales);
      setLastMonthSales(lastMonthSales);
    }
  }, [soldData]);

  // Calculate percentage change
  const percentageChange =
    ((currentMonthSales - lastMonthSales) / lastMonthSales) * 100;
  const percentageChangeText =
    percentageChange >= 0
      ? `+${percentageChange.toFixed(2)}% ${t("LastMonth")}`
      : `${percentageChange.toFixed(2)}% ${t("LastMonth")}`;

  // Process soldData to extract sold dates and count sales per month
  const salesData = soldData.reduce((acc, curr) => {
    if (curr.soldDate) {
      const soldDate = new Date(curr.soldDate);
      const monthYear = soldDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[monthYear]) {
        acc[monthYear] = 1;
      } else {
        acc[monthYear]++;
      }
    }
    return acc;
  }, {});

  // Convert salesData to an array of objects suitable for Recharts
  const chartData = Object.keys(salesData).map((monthYear) => ({
    monthYear,
    sales: salesData[monthYear],
  }));
  const formatYAxisLabel = (value) => {
    return `${value}`;
  };

  return (
    <div className="bar-chart" dir={lang == "ar" ? "rtl" : "ltr"}>
      <div className="bar-chart-info">
        <h5 className="bar-chart-title">{t("TotalSales")}</h5>
        <div className="chart-info-data">
          <div className="info-data-value">{currentMonthSales}</div>
          <div className="info-data-text">
            <FaArrowUpLong />
            <p>{percentageChangeText}</p>
          </div>
        </div>
      </div>
      <BarChart width={800} height={400} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="monthYear" />
        <YAxis
          padding={{ bottom: 10, top: 10 }}
          tickFormatter={formatYAxisLabel}
          tickCount={6}
          axisLine={false}
          tickSize={0}
          tick={{
            fill: `#676767`,
          }}
        />

        <Tooltip />
        <Legend />
        <Bar dataKey="sales" fill="#475be8" />
      </BarChart>
    </div>
  );
};

export default AdminBarChart;
