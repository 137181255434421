import React from 'react'
import Navbar from '../../components/navbar/navbar'
import AreaCards from '../../components/areaCards/AreaCards'
import AreaBarChart from '../../components/areaCharts/AreaBarChart'

const Dashboard = () => {
  
  return (
    <div style={{alignItems:"center",justifyContent:"center",display:"flex",marginTop:"20px",flexDirection:"column",backgroundColor:"#f8f8f8"}}>
      <Navbar/>
      <AreaCards/>
      <div style={{marginTop:"10px"}}>
      <AreaBarChart/>
      </div>
      
    </div>
  )
}

export default Dashboard