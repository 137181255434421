import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      const { user, token } = action.payload;
      state.user = {
        username: user.username,
        id: user._id,
      };
      state.token = token;
      state.isAuthenticated = true;
      // Store token in local storage
      localStorage.setItem("token", token);
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      // Clear token from local storage
      localStorage.removeItem("token");
    },
  },
});

const persistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export const { login, logout } = authSlice.actions;

export default persistedAuthReducer;
