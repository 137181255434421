import { Spin } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ToggleButton = ({ value, toggle, children, info }) => {
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation();
  if (isPending) return <Spin />;
  return (
    <button
      disabled={isPending}
      onClick={async () => {
        try {
          setIsPending(true);
          await toggle(!value, info);
          setIsPending(false);
        } catch (error) {
          setIsPending(false);
          throw error;
        }
      }}
      className={`button view`}
    >
      {children ?? t(!!value ? "notAble" : "able")}
    </button>
  );
};

export default ToggleButton;
