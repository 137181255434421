import { Link } from "react-router-dom";
import "./viewTable.scss";
import { Modal } from "antd";
import { useState, useEffect, useContext } from "react";
import delIcon from "../../../assets/images/delIcon.png";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { LangContext } from "../../../App";
import { useTranslation } from "react-i18next";

const ViewTable = () => {
  const [currentDelId, setcurrentDelId] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [name, setName] = useState();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false); // Loading state

  // Initial pagination settings
  const Token = localStorage.getItem("token");
  axios.defaults.headers.common["X-Auth"] = Token;
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  const deleteFn = () => {
    let uri = `${base_url}user/delete?id=${currentDelId}`;
    axios
      .delete(uri, {
        headers: { Authorization: `Bearer ${Token}` },
      })
      .then((response) => {
        console.log("deleteFn done--> ", response.data.data);

        handleCancel();
        fetchData();
        // console.log(Token)
      })
      .catch((error) => {
        console.log("deleteFn er--> ", error.response);
        console.log(Token);
        handleCancel();
      });
  };

  const nextPage = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: prevPagination.page + 1,
    }));
  };

  const prevPage = () => {
    if (pagination.page > 1) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page - 1,
      }));
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderDelModal = () => {
    return (
      <Modal open={isModalVisible} footer={null} onCancel={handleCancel}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={delIcon} alt="delIcon" style={{ width: "35px" }} />
        </div>
        <div>
          <p style={{ textAlign: "center", fontSize: "24px", margin: "10px" }}>
            {name}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="delBtnModal" onClick={deleteFn}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState();

  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const res = await axios.get(`${base_url}user/getAllUsers`, {
        params: {
          username: query,
          type: "reseller",
          phoneNumber: phoneNumber,
          email: email,
          page: pagination.page,
          limit: pagination.pageSize,
        },
        headers: { "X-Auth": `${Token}` },
      });
      setData(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data (whether successful or not)
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [query, pagination, phoneNumber, email]);
  const { lang } = useContext(LangContext);
  const { t } = useTranslation();
  return (
    <>
      <section
        className="content-area-table"
        dir={`${lang === "ar" ? "rtl" : "ltr"}`}
      >
        <div className="data-table-info">
          <h4 className="data-table-title">{t("users")}</h4>
        </div>

        <div className="data-table-diagram">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>
                  <p>{t("Name")}</p>
                  <input
                    className="input"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  />
                </th>

                <th>{t("type")}</th>
                <th>
                  <p>{t("phoneNumber")}</p>
                  <input
                    className="input"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) =>
                      setPhoneNumber(e.target.value.toLowerCase())
                    }
                  />
                </th>
                <th>
                  <p>{t("email")}</p>
                  <input
                    className="input"
                    style={{ backgroundColor: "white" }}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {data?.map((dataItem, index) => {
                const id =
                  (pagination.page - 1) * pagination.pageSize + index + 1;
                return (
                  <tr key={dataItem.id}>
                    <td>{id}</td>
                    <td>{dataItem.username}</td>

                    {/* {console.log(dataItem.code)} */}

                    <td>{dataItem.type}</td>
                    <td>{dataItem.phoneNumber}</td>
                    <td>{dataItem.email}</td>

                    <td>
                      {/* <div className="buttons">
                        <Link to={`/dashboard/users/editUser/${dataItem._id}`}>
                          <button className={`button view`}>Edit</button>
                        </Link>
                        <span
                          className="deletespan"
                          onClick={() => {
                            setcurrentDelId(dataItem._id);
                            setName(dataItem.username)
                            showModal();
                          }}
                        >
                          <span>
                            <MdDelete style={{ marginTop: "4px" }} />
                          </span>
                        </span>
                      </div> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={prevPage}
              className="logoutBtn"
              disabled={pagination.page === 1}
            >
              Previous
            </button>
            <span>{pagination.page}</span>
            <button onClick={nextPage} className="logoutBtn">
              Next
            </button>
          </div>
        </div>

        {isModalVisible === true && renderDelModal()}
      </section>
    </>
  );
};

export default ViewTable;
