import { Link } from "react-router-dom";
import { Modal } from "antd";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import delIcon from "../../../assets/images/delIcon.png";
import "./viewTable.scss";
import { LangContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ToggleButton from "../../../components/toggle-button";
import Pages from "../../../components/PagesNavigation/Pages";
import { updatePage } from "../../../redux/pageSlice";
import { FaCopy } from "react-icons/fa6";
import { handleCopy } from "../../../utils/helpers";

const ViewTable = () => {
  const [currentDelId, setcurrentDelId] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [code, setCode] = useState("");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);

  const { lang } = useContext(LangContext);
  const [totalPages, setTotalPages] = useState(null);
  const pageValue = useSelector((state) => state.page.pageValue);

  const dispatch = useDispatch();
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  const Token = localStorage.getItem("token");
  axios.defaults.headers.common["X-Auth"] = Token;

  const nextPage = () => {
    dispatch(updatePage(pageValue + 1));
  };

  const prevPage = () => {
    dispatch(updatePage(pageValue - 1));
  };

  const deleteFn = () => {
    let uri = `${base_url}codes/?id=${currentDelId}`;
    axios
      .delete(uri)
      .then((response) => {
        console.log("deleteFn done--> ", response.data);
        handleCancel();
        fetchData();
      })
      .catch((error) => {
        console.log("deleteFn er--> ", error.response);
        handleCancel();
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSoldDateChange = async (id) => {
    try {
      const response = await axios.patch(
        `${base_url}codes/mark-as-sold?id=${id}`,
        {
          soldDate: new Date().toISOString(),
        }
      );
      await fetchData();
      console.log("Sold date updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating sold date:", error);
    }
  };

  const renderDelModal = () => {
    return (
      <Modal open={isModalVisible} footer={null} onCancel={handleCancel}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={delIcon} alt="delIcon" style={{ width: "35px" }} />
        </div>
        <div>
          <p style={{ textAlign: "center", fontSize: "24px", margin: "10px" }}>
            modal
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button className="delBtnModal" onClick={deleteFn}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const [activatedDate, setActivatedDate] = useState("");
  const [expiredDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("");
  const [period, setPeriod] = useState("");

  const fetchData = async (loading) => {
    try {
      setLoading(!!loading);
      const res = await axios.get(`${base_url}codes/codes`, {
        params: {
          resellerId: user.id,
          page: pageValue,
          limit: pagination.pageSize,
          query: query || undefined,
          code: code || undefined,
          activatedDate: activatedDate || undefined,
          period: period || undefined,
          status: status || undefined,
          expiryDate: expiredDate || undefined,
        },
        headers: {
          "Accept-Language": lang,
        },
      });
      const responseData = res.data.data;

      console.log(responseData.data);
      setTotalPages(res.data.pagecount);
      if (Array.isArray(responseData)) {
        setData(responseData);
      } else {
        console.error("Data is not an array:", responseData);
        setData([]); // Fallback to an empty array if data is not an array
      }
      console.log("codes", res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Clear data on error
    } finally {
      setLoading(false);
    }
  };

  const [statusOptions, setStatusOptions] = useState([]);

  const fetchStatusOptions = async () => {
    try {
      const res = await axios.get(`${base_url}codes/status`);
      setStatusOptions(res.data);
      console.log("response from code status", res.data);
    } catch (error) {
      console.error("Error fetching status options:", error);
    }
  };

  useEffect(() => {
    fetchStatusOptions();
    fetchData(true);
  }, [
    activatedDate,
    expiredDate,
    period,
    status,
    query,
    code,
    pagination,
    pageValue,
  ]);

  return (
    <section
      className="content-area-table"
      dir={`${lang === "ar" ? "rtl" : "ltr"}`}
    >
      <div className="data-table-info">
        <h4 className="data-table-title">{t("Codes")}</h4>
      </div>
      <div className="data-table-diagram">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>
                <p>{t("Codes")}</p>
                <input
                  type="text"
                  className="input"
                  value={code}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #0c356a",
                    borderRadius: "5px",
                  }}
                  onChange={(e) => setCode(e.target.value)}
                />
              </th>
              <th>
                <p>{t("activatedDate")}</p>
                <input
                  className="input"
                  type="datetime-local"
                  onChange={(e) =>
                    setActivatedDate(e.target.value.toLowerCase())
                  }
                />
              </th>
              <th>
                <p>{t("expiredDate")}</p>
                <input
                  className="input"
                  type="datetime-local"
                  onChange={(e) => setExpiryDate(e.target.value.toLowerCase())}
                />
              </th>
              <th>
                <p>{t("period")}</p>
                <input
                  className="input"
                  type="number"
                  style={{ backgroundColor: "white" }}
                  onChange={(e) => setPeriod(e.target.value.toLowerCase())}
                />
              </th>
              <th>{t("days")}</th>
              <th>
                <p style={{ display: "flex" }}> {t("status")}</p>
                <select
                  id="type"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="">{t("all")}</option>

                  {Array.isArray(statusOptions) &&
                    statusOptions.map((value) => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                </select>
              </th>
              <th>{t("sold")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) &&
              data.map((dataItem, index) => {
                const formattedDate = new Date(dataItem.activatedDate);
                const formatted = new Date(dataItem.expiryDate);
                const diffInMs = formatted - formattedDate;
                const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                const currentDate = new Date();
                const remainingDays = Math.max(
                  0,
                  Math.floor((formatted - currentDate) / (1000 * 60 * 60 * 24))
                );

                const isValidDate = !isNaN(formattedDate.getTime());

                const id = (pageValue - 1) * pagination.pageSize + index + 1;

                return (
                  <tr key={dataItem.id}>
                    <td>{id}</td>
                    <td>
                      <button
                        onClick={() => handleCopy(dataItem.code)}
                        style={{
                          fontSize: "1.2rem",
                          color: "#0c356a",
                          marginLeft: "5px",
                        }}
                      >
                        <FaCopy />
                      </button>
                      {dataItem.code}
                    </td>
                    <td>{isValidDate ? formattedDate.toDateString() : "-"}</td>
                    <td>
                      {!isNaN(formatted.getTime())
                        ? formatted.toDateString()
                        : "-"}
                    </td>
                    {/* <td>{!isNaN(diffInDays) ? diffInDays : "-"}</td> */}
                    <td>{dataItem.period}</td>
                    <td>{!isNaN(remainingDays) ? remainingDays : "-"}</td>
                    <td>{dataItem.status}</td>
                    <td>
                      {dataItem.soldDate ? (
                        new Date(dataItem.soldDate).toDateString()
                      ) : (
                        <ToggleButton
                          value={false}
                          toggle={() => handleSoldDateChange(dataItem._id)}
                        >
                          {t("sold")}
                        </ToggleButton>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            style={{
              backgroundColor: "#0c356a",
              color: "white",
              padding: "5px 10px",
              borderRadius: "50px",
              fontSize: "1.1rem",
              fontFamily: "Cairo",
            }}
            onClick={prevPage}
            disabled={pageValue === 1}
          >
            {t("previous")}
          </button>

          <Pages totalPages={totalPages} />
          <button
            onClick={nextPage}
            style={{
              backgroundColor: "#0c356a",
              color: "white",
              padding: "5px 10px",
              borderRadius: "50px",
              fontSize: "1.1rem",
              fontFamily: "Cairo",
            }}
          >
            {t("next")}
          </button>
        </div>
      </div>
      {isModalVisible && renderDelModal()}
    </section>
  );
};

export default ViewTable;
