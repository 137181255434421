import AreaCard from "./AreaCard";
import "./AreaCards.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

const AreaCards = () => {
  const [userCount, setUserCount] = useState(0);
  const [codeCount, setCodeCount] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [folderCount, setFolderCount] = useState(0);
  const [folderSubCount, setFolderSubCount] = useState(0);
  const { t } = useTranslation();

  const [soldCode, setSoldCode] = useState([]);
  const Token = localStorage.getItem("token");
  axios.defaults.headers.common["X-Auth"] = Token;
  const user = useSelector((state) => state.auth.user);
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    axios
      .get(`${base_url}codes/codes`, {
        params: {
          resellerId: user.id,
        },
        headers: { "X-Auth": ` ${Token}` },
      })
      .then((response) => {
        const numberOfCodes = response.data.totalcount;
        const numberOfSoldCodes = response.data.data;

        let soldCodeCount = 0;
        numberOfSoldCodes.forEach((item) => {
          if (item.soldDate) {
            // Check if soldDate exists
            soldCodeCount++;
          }
        });

        setSoldCode(soldCodeCount);
        setCodeCount(numberOfCodes);
      })
      .catch((error) => {
        console.error("Error fetching code data:", error);
      });
  }, []);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={codeCount}
        cardInfo={{
          title: t("TotalCodes"),
          value: codeCount,
          text: `${t("TotalCodeDec")}: ${soldCode}`,
        }}
      />
    </section>
  );
};

export default AreaCards;
