import "./App.scss";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { message, Select } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screen/login/Login";
import Dashboard from "./screen/dashbord/Dashboard";
import Codes from "./screen/soldCode/viewCode/Codes";
// import NotSoldCodes from "./screen/notSoldCode/Codes";
import { useSelector } from "react-redux";
import AdminDashboard from "./screen/dashbord/AdminDashboard";
import AdminCodes from "./screen/Admin/soldCodes/Codes";
// import AdminNotSoldCodes from "./screen/Admin/notSoldCodes/Codes";
import Reseller from "./screen/Admin/resellers/Resellers";
import RegistrationForm from "./screen/Register/Register";
import { jwtDecode } from "jwt-decode";
const { Option } = Select;

export const LangContext = createContext();

export const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { setLang } = useContext(LangContext);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  return (
    <Select
      defaultValue="ar"
      onChange={(value) => changeLanguage(value)}
      dropdownClassName="lang-dropdown"
      dropdownRender={(menu) => <div>{menu}</div>}
      style={{ width: "100px", height: "40px", color: "#0c356a" }}
    >
      <Option value="ar">
        <span
          style={{
            display: "inline-block",
            width: "100%",
            height: "40px",
            fontFamily: "Cairo",
            color: "#0c356a",
          }}
        >
          عربي
        </span>
      </Option>
      <Option value="en">
        <span
          style={{
            display: "inline-block",
            width: "100%",
            height: "40px",
            fontFamily: "Cairo",
            color: "#0c356a",
          }}
        >
          English
        </span>
      </Option>
    </Select>
  );
};

const getUserType = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.type;
    } catch (error) {
      message.error("Invalid Token", error);
      return null;
    }
  }

  return null;
};

function App() {
  const [lang, setLang] = useState("ar");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userType = getUserType();

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated && userType === "reseller" ? (
                <Dashboard />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated && userType === "admin" ? (
                <AdminDashboard />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegistrationForm />} />
          {isAuthenticated && userType === "reseller" ? (
            <Route>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/sold" element={<Codes />} />

              {/* <Route path="/dashboard/notSold" element={<NotSoldCodes />} /> */}
            </Route>
          ) : (
            <Route path="/*" element={<Login />} />
          )}
          {isAuthenticated && userType === "admin" ? (
            <Route>
              <Route path="/adminDashboard" element={<AdminDashboard />} />
              <Route path="/adminDashboard/sold" element={<AdminCodes />} />
              <Route path="/adminDashboard/reseller" element={<Reseller />} />
              {/* <Route
                path="/adminDashboard/notSold"
                element={<AdminNotSoldCodes />}
              /> */}
            </Route>
          ) : (
            <Route path="/*" element={<Login />} />
          )}
        </Routes>
      </Router>
    </LangContext.Provider>
  );
}

export default App;
