// import React from 'react'
import styles from './loader.module.css'

const Loader=() =>{
  return (
    
     <span className={styles.loader}></span>
  )
}

export default Loader