
import Navbar from "../../../components/navbar/navbar";
import ViewTable from "./viewTable";
const Codes = () => {
 
  return (    
    <div >
      <div style={{alignItems:"center",justifyContent:"center",display:"flex",marginTop:"30px"}}>
      <Navbar/>
      </div>
      <div style={{alignItems:"center",justifyContent:"center",display:"flex",marginTop:"30px"}}>

      
           <ViewTable />
      </div>
           
      </div>
  )
}

export default Codes