import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import styles from "./registerForm.module.css";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import axios from "axios";
import { message, Spin } from "antd";
import PhoneInput from "react-phone-number-input";
import Loader from "../../components/loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { LangContext } from "../../App";

function RegistrationForm() {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [resellerCountryCode, setSelectedCountry] = useState(null);
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
        // console.log(data.userCountryCode)
      });
  }, []);

  const validateForm = () => {
    let errors = {};

    if (!username.trim()) {
      errors.username = "الاسم مطلوب";
    }

    if (!email.trim()) {
      errors.email = "الايميل مطلوب";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "الايميل يجب ان يكون صحيح";
    }

    if (!password.trim()) {
      errors.password = "كلمة المرور مطلوبه";
    } else if (password.length < 6) {
      errors.password = "كلمة المرور يجب ان تكون صحيحه";
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = "رقم التليفون مطلوب";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const { lang } = useContext(LangContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      setLoading(true);
      axios
        .post(`${base_url}user/resellerRegister`, {
          username,
          email,
          password,
          phoneNumber,
          resellerCountryCode,
        })
        .then((response) => {
          console.log("Registration successful:", response.data);
          navigate("/login");
          // You can perform any action upon successful registration, like redirecting to another page.
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.response.data.message);
          console.error("Registration failed:", error);
          // Handle registration failure, display error message, etc.
          // setLoading(false)
        });
    }
  };

  return (
    <div className={styles.container} dir={`${lang === "ar" ? "rtl" : "ltr"}`}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          {t("Register")}
        </h1>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          style={{ marginTop: "10px" }}
        />
        {errors.username && <span>{errors.username}</span>}

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={{ marginTop: "10px" }}
        />
        {errors.email && <span>{errors.email}</span>}

        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        />
        {errors.password && <span>{errors.password}</span>}

        <Select
          options={countries}
          value={resellerCountryCode}
          styles={{
            width: "100%",
          }}
          onChange={(selectedOption) => setSelectedCountry(selectedOption)}
          placeholder="select your country"
        />

        <PhoneInput
          defaultCountry="EG"
          placeholder="Enter phone number"
          value={phoneNumber}
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          onChange={setPhoneNumber}
        />
        <p> {errors.phoneNumber && <span>{errors.phoneNumber}</span>}</p>

        {loading ? <Spin /> : <button type="submit">{t("Register")}</button>}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <Link to="/login" style={{ padding: "10px", borderRadius: "10px" }}>
            {t("Login")}
          </Link>
        </div>
      </form>
    </div>
  );
}

export default RegistrationForm;
