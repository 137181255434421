import React from 'react'

import AdminCards from '../../components/areaCards/AdminCards'
import AdminBarChart from '../../components/areaCharts/AdminBarChart'
import AdminNavbar from '../../components/navbar/navbarAdmin'

const AdminDashboard = () => {
  return (
    <div style={{alignItems:"center",justifyContent:"center", gap:"5px",display:"flex",marginTop:"20px",flexDirection:"column",backgroundColor:"#f8f8f8"}}>
      <AdminNavbar/>
      <AdminCards/>
      <div style={{marginTop:"10px"}}>
      <AdminBarChart/>
      </div>
      
    </div>
  )
}

export default AdminDashboard