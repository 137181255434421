// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pages_allPages__iB0cb {
  display: flex;
  gap: 2rem;
}
.Pages_navContainer__p-El9 {
  display: flex;
  gap: 5px;
  max-width: 600px;
  overflow: scroll;
  scrollbar-width: 2px;
}

.Pages_navContainer__p-El9 button,
.Pages_allPages__iB0cb button {
  border: 1px solid var(--blue-color);
  padding: 5px;
  height: 30px;
  min-width: 30px;
  border-radius: 999px;
}

.Pages_allPages__iB0cb button:hover {
  background-color: var(--blue-color);
  color: #fff;
}

.Pages_navContainer__p-El9 .Pages_activeBtn__apQqz {
  background-color: var(--blue-color);
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/PagesNavigation/Pages.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,aAAa;EACb,QAAQ;EACR,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;;EAEE,mCAAmC;EACnC,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb;;AAEA;EACE,mCAAmC;EACnC,WAAW;AACb","sourcesContent":[".allPages {\n  display: flex;\n  gap: 2rem;\n}\n.navContainer {\n  display: flex;\n  gap: 5px;\n  max-width: 600px;\n  overflow: scroll;\n  scrollbar-width: 2px;\n}\n\n.navContainer button,\n.allPages button {\n  border: 1px solid var(--blue-color);\n  padding: 5px;\n  height: 30px;\n  min-width: 30px;\n  border-radius: 999px;\n}\n\n.allPages button:hover {\n  background-color: var(--blue-color);\n  color: #fff;\n}\n\n.navContainer .activeBtn {\n  background-color: var(--blue-color);\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allPages": `Pages_allPages__iB0cb`,
	"navContainer": `Pages_navContainer__p-El9`,
	"activeBtn": `Pages_activeBtn__apQqz`
};
export default ___CSS_LOADER_EXPORT___;
