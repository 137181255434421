// import { useContext, useState } from "react";
// import axios from "axios";
// import styles from "./loginForm.module.css";
// import { useTranslation } from "react-i18next";
// import { Link, useNavigate } from "react-router-dom";
// import {Spin} from 'antd'
// import Loader from "../../components/loader/Loader";
// import { useDispatch,useSelector } from "react-redux";

// import { login } from "../../redux/authSlice";
// import { LangContext } from "../../App";

// const Login = () => {
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [user, setUser] = useState({
//     email: "",
//     password: "",
//   });
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { t } = useTranslation();
//   const userType = useSelector((state) => state.auth.user);

//   const handleLogin = async (e) => {
//     e.preventDefault();
//     try {
//       setLoading(true);
//       const url = "${base_url}user/reseller/login";
//       const res = await axios.post(url, user);
//      await dispatch(login({ user: res.data.user, token: res.data.token }));
//       if (userType.type === "reseller") {
//         await navigate("/dashboard");
//       }
//       if(userType.type ==='admin' || userType.type ==='moderator'){
//        await navigate("/adminDashboard");
//       }
//     } catch (error) {
//       if (
//         error.response &&
//         error.response.status >= 400 &&
//         error.response.status <= 500
//       ) {
//         setError(error.response.data.message);
//       } else {
//         setError("An error occurred during login.");
//       }
//     } finally {
//       setLoading(false);
//     }
//   };
//   const { lang } = useContext(LangContext);

//   return (
//     <div className={styles.container} dir={`${lang === "ar" ? "rtl" : "ltr"}`}>
//       <form className={styles.form}>
//         <h1>{t("adminPanel")}</h1>
//         <input
//           id="email"
//           type="text"
//           value={user.email}
//           onChange={(e) => setUser({ ...user, email: e.target.value })}
//           placeholder="email"
//         />
//         <input
//           id="password"
//           type="password"
//           value={user.password}
//           onChange={(e) => setUser({ ...user, password: e.target.value })}
//           placeholder="password"
//         />
//         {loading ? <Spin /> : <button onClick={handleLogin}>Login</button>}
//         {error && <p className="text-red-600">{error}</p>}

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginTop: "10px",
//             padding: "10px",
//             borderRadius: "10px",
//           }}
//         >
//           <Link to="/register" style={{ padding: "10px", borderRadius: "10px" }}>
//            {t("Register")}
//           </Link>
//         </div>
//       </form>

//     </div>
//   );
// };

// export default Login;

import { useContext, useState, useEffect } from "react";
import axios from "axios";
import styles from "./loginForm.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { message, Spin } from "antd";

import { useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";
import { LangContext } from "../../App";
import { jwtDecode } from "jwt-decode";

const getUserType = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.type;
    } catch (error) {
      message.error("Invalid Token", error);
      return null;
    }
  }

  return null;
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userType = getUserType();
  const base_url = process.env.REACT_APP_PUBLIC_URL;
  useEffect(() => {
    if (userType) {
      if (userType === "reseller") {
        navigate("/dashboard");
      } else if (userType === "admin" || userType === "moderator") {
        navigate("/adminDashboard");
      }
    }
  }, [userType, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const url = `${base_url}user/reseller/login`;
      const res = await axios.post(url, user);
      await dispatch(login({ user: res.data.user, token: res.data.token }));
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during login.");
      }
      console.error("Login error:", error); // Log the error for debugging purposes
    } finally {
      setLoading(false);
    }
  };

  const { lang } = useContext(LangContext);

  return (
    <div className={styles.container} dir={`${lang === "ar" ? "rtl" : "ltr"}`}>
      <form className={styles.form}>
        <h1>{t("adminPanel")}</h1>
        <input
          id="email"
          type="text"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          placeholder="email"
        />
        <input
          id="password"
          type="password"
          value={user.password}
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          placeholder="password"
        />
        {loading ? <Spin /> : <button onClick={handleLogin}>Login</button>}
        {error && <p className="text-red-600">{error}</p>}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <Link
            to="/register"
            style={{ padding: "10px", borderRadius: "10px" }}
          >
            {t("Register")}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
