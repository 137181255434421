// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registerForm_form__58XVh {
  background-color: var(--bgSoft);
  padding: 50px;
  gap: 40px;
  font-family: "Cairo";

}
.registerForm_container__r3YOq {
  display: flex;
  justify-content: center;
  padding: 100px;
 

}

.registerForm_error__msg__Gz0Q\\+ {
  color: red;
  text-align: center;
  font-family: "Cairo";

}

.registerForm_form__58XVh input {
  width: 100%;
  padding: 25px;
  border: 2px solid #2e374a;
  border-radius: 5px;
  background-color: var(--bg);
  color: var(--text);
}

.registerForm_form__58XVh button {
  width: 100%;
  padding: 20px;
  background-color: #194cbc;
  color: white;
  border: none;
  font-family: "Cairo";

  cursor: pointer;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/screen/Register/registerForm.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,SAAS;EACT,oBAAoB;;AAEtB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;;;AAGhB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,oBAAoB;;AAEtB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,oBAAoB;;EAEpB,eAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,wBAAwB;EACxB,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".form {\n  background-color: var(--bgSoft);\n  padding: 50px;\n  gap: 40px;\n  font-family: \"Cairo\";\n\n}\n.container {\n  display: flex;\n  justify-content: center;\n  padding: 100px;\n \n\n}\n\n.error__msg {\n  color: red;\n  text-align: center;\n  font-family: \"Cairo\";\n\n}\n\n.form input {\n  width: 100%;\n  padding: 25px;\n  border: 2px solid #2e374a;\n  border-radius: 5px;\n  background-color: var(--bg);\n  color: var(--text);\n}\n\n.form button {\n  width: 100%;\n  padding: 20px;\n  background-color: #194cbc;\n  color: white;\n  border: none;\n  font-family: \"Cairo\";\n\n  cursor: pointer;\n  border-radius: 30px;\n  -webkit-border-radius: 30px;\n  -moz-border-radius: 30px;\n  -ms-border-radius: 30px;\n  -o-border-radius: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `registerForm_form__58XVh`,
	"container": `registerForm_container__r3YOq`,
	"error__msg": `registerForm_error__msg__Gz0Q+`
};
export default ___CSS_LOADER_EXPORT___;
