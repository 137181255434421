// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__M-FUL {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: loader_rotate__PZK3t 1s linear infinite
}
.loader_loader__M-FUL::before , .loader_loader__M-FUL::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: loader_prixClipFix__jAAqm 2s linear infinite;
}
.loader_loader__M-FUL::after{
  border-color: #FF3D00;
  animation: loader_prixClipFix__jAAqm 2s linear infinite , loader_rotate__PZK3t 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes loader_rotate__PZK3t {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes loader_prixClipFix__jAAqm {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB;AACF;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,sBAAsB;EACtB,uDAA0C;AAC5C;AACA;EACE,qBAAqB;EACrB,2GAA+E;EAC/E,UAAU;AACZ;;AAEA;EACE,MAAM,uBAAuB;EAC7B,QAAQ,yBAAyB;AACnC;;AAEA;IACI,MAAM,8CAA8C;IACpD,MAAM,0DAA0D;IAChE,MAAM,mEAAmE;IACzE,MAAM,6DAA6D;IACnE,MAAM,0DAA0D;AACpE","sourcesContent":[".loader {\n  width: 48px;\n  height: 48px;\n  border-radius: 50%;\n  position: relative;\n  animation: rotate 1s linear infinite\n}\n.loader::before , .loader::after {\n  content: \"\";\n  box-sizing: border-box;\n  position: absolute;\n  inset: 0px;\n  border-radius: 50%;\n  border: 5px solid #FFF;\n  animation: prixClipFix 2s linear infinite ;\n}\n.loader::after{\n  border-color: #FF3D00;\n  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;\n  inset: 6px;\n}\n\n@keyframes rotate {\n  0%   {transform: rotate(0deg)}\n  100%   {transform: rotate(360deg)}\n}\n\n@keyframes prixClipFix {\n    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}\n    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}\n    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}\n    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}\n    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__M-FUL`,
	"rotate": `loader_rotate__PZK3t`,
	"prixClipFix": `loader_prixClipFix__jAAqm`
};
export default ___CSS_LOADER_EXPORT___;
