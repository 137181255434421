import { Link } from "react-router-dom";
import { Modal, Spin, AutoComplete, message } from "antd";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MdDelete, MdInfo } from "react-icons/md";

import { LangContext } from "../../../App";
import "./viewTable.scss";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import ToggleButton from "../../../components/toggle-button";
import { useDispatch, useSelector } from "react-redux";
import Pages from "../../../components/PagesNavigation/Pages";
import { updatePage } from "../../../redux/pageSlice";
import { FaCopy } from "react-icons/fa6";
import { handleCopy } from "../../../utils/helpers";

const ViewTable = () => {
  const [currentDelId, setcurrentDelId] = useState(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 }); // Initial pagination settings
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [resellerId, setResellerId] = useState(null);
  const [resellers, setResellers] = useState([]);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(null);
  const { lang } = useContext(LangContext);
  const Token = localStorage.getItem("token");
  const base_url = process.env.REACT_APP_PUBLIC_URL;

  const pageValue = useSelector((state) => state.page.pageValue);
  const dispatch = useDispatch();
  console.log(pageValue);

  axios.defaults.headers.common["Authorization"] = `Bearer ${Token}`;
  const fetchResellers = async () => {
    try {
      const res = await axios.get(`${base_url}user/getAllUsers`, {
        params: {
          limit: 1000,
          type: "reseller",
          username: search || undefined,
        },
        headers: {
          "Accept-Language": lang,
        },
      });
      setResellers(
        res.data.data.map((reseller) => ({
          label: reseller.username,
          value: reseller._id,
        }))
      );
    } catch (error) {
      message.error(error.reponse.data.message);
    }
  };

  useEffect(() => {
    fetchResellers();
  }, [search]);

  const nextPage = () => {
    dispatch(updatePage(pageValue + 1));
  };

  const prevPage = () => {
    dispatch(updatePage(pageValue - 1));
  };

  const deleteFn = () => {
    let uri = `${base_url}codes/?id=${currentDelId}`;
    axios
      .delete(uri)
      .then((response) => {
        console.log("deleteFn done--> ", response.data);
        handleCancel();
        fetchData();
      })
      .catch((error) => {
        console.log("deleteFn er--> ", error.response);
        handleCancel();
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSoldDateChange = async (id) => {
    try {
      const response = await axios.patch(
        `${base_url}codes/mark-as-sold?id=${id}`,
        {
          soldDate: new Date().toISOString(),
        }
      );
      await fetchData();
      console.log("Sold date updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating sold date:", error);
    }
  };

  axios.defaults.headers.common["X-Auth"] = Token;
  const [isModalVisibleUser, setIsModalVisibleUser] = useState(false);

  const showModalUser = () => {
    setIsModalVisibleUser(true);
  };
  const handleCancelUser = () => {
    setIsModalVisibleUser(false);
  };
  const { t } = useTranslation();
  const [activatedDate, setActivatedDate] = useState("");
  const [expiredDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("");
  const [period, setPeriod] = useState("");
  const [modalUsername, setModalUserName] = useState();
  const [modalEmail, setModalEmail] = useState();
  const [modalPhone, setModalPhone] = useState();
  const [modalResellerCountryCode, setModalResellerCountryCode] = useState();
  const fetchData = async (loading) => {
    try {
      setLoading(!!loading);
      const res = await axios.get(`${base_url}codes/codes`, {
        params: {
          page: pageValue,
          limit: pagination.pageSize,
          code: query || undefined, // Filter by code
          activatedDate: activatedDate || undefined, // Filter by expiryDate
          period: period || undefined, // Filter by period
          status: status || undefined, // Filter by status
          expiryDate: expiredDate || undefined,
          resellerId: resellerId || undefined,
          email: resellerId || undefined,
        },
        headers: {
          "Accept-Language": lang,
          "Content-Type": "application/json",
        },
      });
      setData(res.data.data);
      console.log("Codes", res.data);
      setTotalPages(res.data.pagecount);
    } catch (error) {
      console.log(
        "API error:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const getUserId = async (userId) => {
    try {
      setLoading(true); // Set loading to true when fetching data
      console.log(userId);
      const res = await axios.get(`${base_url}user/find/${userId}`, {
        headers: { "X-Auth": ` ${Token}` },
      });

      // Set userId and username state variables
      setModalUserName(res.data.username);
      setModalEmail(res.data.email);
      setModalPhone(res.data.phoneNumber);
      setModalResellerCountryCode(
        res.data.resellerCountryCode ? res.data.resellerCountryCode.value : null
      );
    } catch (error) {
    } finally {
      setLoading(false); // Set loading to false after fetching data (whether successful or not)
    }
  };

  const renderUserModal = () => {
    return (
      <Modal
        open={isModalVisibleUser}
        footer={null}
        onCancel={handleCancelUser}
        style={{ fontFamily: "Cairo" }}
      >
        {loading ? (
          <Spin />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  margin: "10px",
                  color: "#0c356a",
                  borderBottom: "2px solid #0c356a",
                }}
              >
                {" "}
                {t("ResellerDetails")}
              </p>
            </div>
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  margin: "10px",
                }}
              >
                {modalUsername}:{t("Name")}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  margin: "10px",
                }}
              >
                {modalEmail}: {t("email")}
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  margin: "10px",
                }}
              >
                {t("phoneNumber")} : {modalPhone}
              </p>
              <ReactCountryFlag
                countryCode={modalResellerCountryCode}
                svg
                style={{
                  width: "100%",
                  height: "20%",
                }}
                title={modalResellerCountryCode}
              />
            </div>
          </>
        )}
      </Modal>
    );
  };
  const [selectedResellerName, setSelectedResellerName] = useState(""); // Track the selected reseller name
  const [statusOptions, setStatusOptions] = useState([]);

  const fetchStatusOptions = async () => {
    try {
      const res = await axios.get(`${base_url}codes/status`, {
        headers: { "X-Auth": Token },
      });
      setStatusOptions(res.data);
      // Assume res.data contains the status options array
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching status options:", error);
    }
  };

  useEffect(() => {
    // Fetch roles data from API
    axios
      .get(`${base_url}codes/status`, {
        headers: { "X-Auth": `${Token}` },
      })
      .then((response) => {
        console.log("status", response.data);
        setStatusOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
  }, []);

  useEffect(() => {
    fetchData(true);
    fetchStatusOptions();
  }, [
    query,
    pagination,
    activatedDate,
    expiredDate,
    period,
    status,
    resellerId,
    pageValue,
  ]); // Also trigger useEffect when pagination changes
  return (
    <section
      className="content-area-table"
      dir={`${lang === "ar" ? "rtl" : "ltr"}`}
    >
      <div className="data-table-info">
        <h4 className="data-table-title">{t("Codes")}</h4>
      </div>
      <div className="select-reseller">
        <h3>اختر موزع</h3>
        <AutoComplete
          options={resellers}
          allowClear
          autoClearSearchValue
          value={selectedResellerName}
          placeholder="Select reseller"
          onSelect={(v, option) => {
            setResellerId(v);
            setSelectedResellerName(option.label); // Set the selected reseller name
          }}
          onDeselect={() => {
            setResellerId(null);
            setSelectedResellerName(""); // Clear the selected reseller name
            // setSearch("");
          }}
          onChange={(v) => {
            if (!v) {
              // setSearch("");
              setResellerId(null);
              setSelectedResellerName(""); // Clear the selected reseller name
            }
          }}
          onSearch={(v) => {
            setSearch(v);
          }}
          style={{
            maxWidth: "250px",
            width: "100%",
          }}
        />
      </div>
      <div className="data-table-diagram">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>
                <p>{t("Codes")}</p>
                <input
                  className="input"
                  style={{
                    backgroundColor: "white",
                    width: "80%",
                    border: "1px solid #0c356a",
                    borderRadius: "5px",
                  }}
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </th>
              <th>
                <p>{t("activatedDate")}</p>
                <input
                  className="input"
                  type="datetime-local"
                  onChange={(e) =>
                    setActivatedDate(e.target.value.toLowerCase())
                  }
                />
              </th>
              <th>
                <p>{t("expiredDate")}</p>
                <input
                  className="input"
                  type="datetime-local"
                  onChange={(e) => setExpiryDate(e.target.value.toLowerCase())}
                />
              </th>
              <th>
                <p>{t("period")}</p>
                <input
                  className="input"
                  type="number"
                  style={{ backgroundColor: "white", width: "80%" }}
                  onChange={(e) => setPeriod(e.target.value.toLowerCase())}
                />
              </th>
              <th>{t("days")}</th>
              <th>
                <p style={{ display: "flex" }}> {t("status")}</p>
                <select
                  id="type"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="">{t("all")}</option>

                  {statusOptions.map((value) => {
                    return <option value={value}>{value}</option>;
                  })}
                </select>
              </th>
              <th>{t("sold")}</th>
              <th>{t("details")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((dataItem, index) => {
              const formattedDate = new Date(dataItem.activatedDate);
              const formatted = new Date(dataItem.expiryDate);
              const diffInMs = formatted - formattedDate;
              const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

              const currentDate = new Date();
              const remainingDays = Math.max(
                0,
                Math.floor((formatted - currentDate) / (1000 * 60 * 60 * 24))
              );

              const isValidDate = !isNaN(formattedDate.getTime());
              const id = (pageValue - 1) * pagination.pageSize + index + 1;
              return (
                <tr key={dataItem.id}>
                  <td>{id}</td>
                  <td>
                    <button
                      className="copybtn"
                      onClick={() => handleCopy(dataItem.code)}
                    >
                      <FaCopy />
                    </button>
                    {dataItem.code}{" "}
                  </td>
                  <td>{isValidDate ? formattedDate.toDateString() : "-"}</td>
                  <td>
                    {!isNaN(formatted.getTime())
                      ? formatted.toDateString()
                      : "-"}
                  </td>
                  {/* <td>{!isNaN(diffInDays) ? diffInDays : "-"}</td> */}
                  <td>{dataItem.period}</td>

                  <td>{!isNaN(remainingDays) ? remainingDays : "-"}</td>
                  <td>{dataItem.status}</td>
                  <td>
                    {dataItem.soldDate ? (
                      new Date(dataItem.soldDate).toDateString()
                    ) : (
                      <ToggleButton
                        value={false}
                        toggle={() => handleSoldDateChange(dataItem._id)}
                      >
                        {t("sold")}
                      </ToggleButton>
                    )}
                  </td>
                  <td>
                    <div className="buttons">
                      {/* <Link
                        to={`/dashboard/codes/editCode/CodeID/${dataItem._id}`}
                      >
                        <button className={`button view`}>Edit</button>
                      </Link>
                      <span
                        className="deletespan"
                        onClick={() => {
                          setcurrentDelId(dataItem._id);
                          setName(dataItem.code);
                          showModal();
                        }}
                      >
                        <MdDelete style={{ marginTop: "4px" }} />
                      </span> */}

                      <span
                        className="deletespan"
                        onClick={() => {
                          getUserId(dataItem.resellerId._id);
                          // setName(dataItem.)
                          showModalUser();
                        }}
                      >
                        <MdInfo style={{ marginTop: "4px" }} />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          className="logoutBtn"
          onClick={prevPage}
          disabled={pageValue === 1}
        >
          {t("previous")}
        </button>

        <Pages totalPages={totalPages} />
        <button
          onClick={nextPage}
          className="logoutBtn"
          disabled={pageValue === totalPages}
        >
          {t("next")}
        </button>
      </div>
      {/* {isModalVisible && renderDelModal()} */}
      {isModalVisibleUser === true && renderUserModal()}
    </section>
  );
};

export default ViewTable;
